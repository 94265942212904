import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { auth, googleProvider } from '../../../../../firebaseConfig';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
} from 'firebase/auth';
import axiosInstance from '../../../../../helpers/axiosInstance';
import { authState as recoilAuthState } from '../../../../../atoms';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import AvatarSelection from '../../../../Avatars/AvatarSelection';
import EmailUseDialogComponent from '../../../Dialog/EmailUseDialogComponent';

const validationSchema = yup.object({
  firstName: yup.string().min(2).max(50).required('First name is required'),
  lastName: yup.string().min(2).max(50).required('Last name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  avatar: yup.string().required('Avatar selection is required'),
  role: yup.string().required('Role selection is required'),
});

const RoleSelectionForm = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      role: '',
    },
    validationSchema: yup.object({
      role: yup.string().required('Role selection is required'),
    }),
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        fullWidth
        error={formik.touched.role && Boolean(formik.errors.role)}
      >
        <InputLabel id="role-select-label">Role</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          name="role"
          value={formik.values.role}
          label="Role"
          onChange={formik.handleChange}
        >
          <MenuItem value="writer">Writer</MenuItem>
          <MenuItem value="journalist">Journalist</MenuItem>
          <MenuItem value="educator">Educator</MenuItem>
          <MenuItem value="business">Business</MenuItem>
          <MenuItem value="digital-marketer">Digital Marketer</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
        {formik.touched.role && formik.errors.role && (
          <FormHelperText>{formik.errors.role}</FormHelperText>
        )}
      </FormControl>
      <Button fullWidth variant="contained" type="submit" sx={{ mt: 2 }}>
        Continue to Dashboard
      </Button>
    </form>
  );
};

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [, setAuthState] = useRecoilState(recoilAuthState);
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openEmailInUseDialog, setOpenEmailInUseDialog] = useState(false);
  const [showRoleSelection, setShowRoleSelection] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      avatar: '',
      role: '',
    },
    validationSchema,
    onSubmit: handleEmailPasswordSignUp,
  });

  async function handleEmailPasswordSignUp(values) {
    try {
      const { firstName, lastName, email, password, avatar, role } = values;
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
        photoURL: avatar,
      });

      await axiosInstance.post('/api/auth/register', {
        uid: user.uid,
        email,
        firstName,
        lastName,
        avatar,
        role,
      });

      navigate('/email-verification');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setOpenEmailInUseDialog(true);
      } else {
        console.error('Error:', error);
      }
    }
  }

  async function handleGoogleSignUp() {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const googleUser = user.providerData.find(
        (provider) => provider.providerId === 'google.com'
      );
      const googleId = googleUser ? googleUser.uid : null;

      let firstName = 'User';
      let lastName = 'Name';
      if (user.displayName) {
        const nameParts = user.displayName.trim().split(' ');
        firstName = nameParts[0] || 'User';
        lastName = nameParts.slice(1).join(' ') || 'Name';
      }

      setGoogleUser({
        uid: user.uid,
        email: user.email,
        firstName,
        lastName,
        avatar: user.photoURL,
        googleId,
        emailVerified: user.emailVerified,
      });

      setShowRoleSelection(true);
    } catch (error) {
      console.error('Google sign-up failed:', error);
    }
  }

  async function handleRoleSubmit(values) {
    try {
      const user = auth.currentUser;
      await updateProfile(user, {
        displayName: `${googleUser.firstName} ${googleUser.lastName}`,
      });

      const idToken = await user.getIdToken();

      const response = await axiosInstance.post(
        '/api/auth/google-register',
        {
          ...googleUser,
          role: values.role,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data.success) {
        // Update the auth state with the user data from the response
        setAuthState({
          user: {
            ...response.data.user,
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: true, // Ensure this is set to true for Google users
          },
          accessToken: idToken,
          isAuthenticated: true,
          isLoading: false,
        });

        // Navigate based on role
        switch (response.data.user.role) {
          case 'journalist':
            navigate('/my-journalist-dashboard');
            break;
          case 'business':
            navigate('/my-business-dashboard');
            break;
          case 'educator':
            navigate('/my-teaching-dashboard');
            break;
          case 'digital-marketer':
            navigate('/my-digital-marketing-dashboard');
            break;
          case 'writer':
            navigate('/my-writing-dashboard');
            break;
          default:
            navigate('/dashboard');
        }
      } else {
        throw new Error(response.data.message || 'Failed to register user');
      }
    } catch (error) {
      console.error('Role submission failed:', error);
      // Handle error (e.g., show error message to user)
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    formik.setFieldValue(
      'role',
      selectedRole === 'other' ? 'user' : selectedRole
    );
  };

  // const handleRoleChange = (event) => {
  //   const selectedRole = event.target.value;
  //   formik.setFieldValue('role', selectedRole);
  // };

  return (
    <Box>
      {showRoleSelection ? (
        <>
          <Typography variant="h4" gutterBottom>
            Select Your Role
          </Typography>
          <RoleSelectionForm onSubmit={handleRoleSubmit} />
        </>
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            Create an account
          </Typography>
          <Typography color="text.secondary" marginBottom={2}>
            And get 10,000 credits free to explore our various tools - no
            credit/debit card required!
          </Typography>

          <Button
            fullWidth
            variant="outlined"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleSignUp}
            sx={{ mb: 2 }}
          >
            Sign up with Google
          </Button>

          <Divider sx={{ my: 2 }}>
            <Typography variant="body2" color="text.secondary">
              OR
            </Typography>
          </Divider>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First name"
                  variant="outlined"
                  name={'firstName'}
                  fullWidth
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last name"
                  variant="outlined"
                  name={'lastName'}
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  name={'email'}
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  variant="outlined"
                  name={'password'}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={formik.touched.role && Boolean(formik.errors.role)}
                >
                  <InputLabel id="role-select-label">Role</InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={formik.values.role}
                    label="Role"
                    onChange={handleRoleChange}
                  >
                    <MenuItem value="writer">Writer</MenuItem>
                    <MenuItem value="journalist">Journalist</MenuItem>
                    <MenuItem value="educator">Educator</MenuItem>
                    <MenuItem value="business">Business</MenuItem>
                    <MenuItem value="digital-marketer">
                      Digital Marketer
                    </MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {formik.touched.role && formik.errors.role && (
                    <FormHelperText>{formik.errors.role}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
                  Select your Avatar
                </Typography>
                <AvatarSelection
                  selectedAvatar={formik.values.avatar}
                  setSelectedAvatar={(avatar) =>
                    formik.setFieldValue('avatar', avatar)
                  }
                />
                {formik.touched.avatar && Boolean(formik.errors.avatar) && (
                  <Typography
                    variant={'subtitle2'}
                    sx={{ color: 'red', marginTop: 2 }}
                  >
                    {formik.errors.avatar}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      name="terms"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="subtitle2" color="text.secondary">
                      By clicking the "Sign up" button, you agree to our{' '}
                      <Link
                        component={'a'}
                        color={'primary'}
                        href={'/company-terms'}
                        underline={'none'}
                      >
                        company's terms and conditions.
                      </Link>
                    </Typography>
                  }
                />
              </Grid>

              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column-reverse', sm: 'row' }}
                  alignItems={{ xs: 'center', sm: 'center' }}
                  justifyContent={{ xs: 'center', sm: 'space-between' }}
                  width={1}
                  maxWidth={600}
                  margin={'0 auto'}
                >
                  <Box
                    marginTop={{ xs: 2, sm: 0 }}
                    marginBottom={{ xs: 1, sm: 0 }}
                    width={{ xs: '100%', sm: 'auto' }}
                    textAlign={{ xs: 'center', sm: 'left' }}
                  >
                    <Typography variant={'subtitle2'}>
                      Already have an account?{' '}
                      <Link
                        component={'a'}
                        color={'primary'}
                        href={'/login'}
                        underline={'none'}
                      >
                        Login.
                      </Link>
                    </Typography>
                  </Box>
                  <Box width={{ xs: '100%', sm: 'auto' }}>
                    <Button
                      fullWidth={true}
                      size={'large'}
                      variant={'contained'}
                      type={'submit'}
                      disabled={!termsAccepted}
                    >
                      Sign up
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      <EmailUseDialogComponent
        open={openEmailInUseDialog}
        handleClose={() => setOpenEmailInUseDialog(false)}
        navigate={navigate}
      />
    </Box>
  );
};

export default RegistrationForm;
