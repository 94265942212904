//client/src/components/screens/UserKnowledgeBaseScreen.js
import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import instance from '../../helpers/axiosInstance';
import {
  Box,
  Container,
  Typography,
  TextField,
  FormControl,
  Grid,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import filterAndRenderKnowledge from './filterAndRenderKnowledge';
import { authState } from '../../atoms';

const UserKnowledgeScreen = (props) => {
  // Recoil state
  const auth = useRecoilValue(authState);
  const setAuth = useSetRecoilState(authState);
  const { isAuthenticated, accessToken, refreshToken, tokenExpiry } = auth;

  const [knowledgeBase, setKnowledgeBase] = useState([]);
  const [search, setSearch] = useState('');
  const [companyNameFilter, setCompanyNameFilter] = useState('');
  const [itemNameFilter, setItemNameFilter] = useState('');
  const [page, setPage] = useState(1);
  const [companyNames, setCompanyNames] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [itemNameOptions, setItemNameOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const itemsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCompanyNameFilterChange = (event) => {
    setCompanyNameFilter(event.target.value);

    // Filter itemNames based on selected companyName
    const filteredItemNames = knowledgeBase
      .filter((item) => item.companyName === event.target.value)
      .map((item) => item.itemName);

    setItemNameOptions(filteredItemNames);
  };

  const handleItemNameFilterChange = (event) => {
    setItemNameFilter(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemSelect = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const handleBulkDelete = async () => {
    if (!isAuthenticated || !accessToken) {
      console.error('Authentication required or access token missing.');
      return;
    }

    try {
      await instance.post(
        '/api/knowledgeBase/bulk-delete',
        {
          ids: selectedItems,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Refresh the knowledge base after deletion
      const response = await instance.get('/api/knowledgeBase/user-knowledge', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setKnowledgeBase(response.data.entries);
      setSelectedItems([]);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error performing bulk delete:', error);
    }
  };

  // Fetch user knowledge data on mount
  useEffect(() => {
    const fetchUserKnowledge = async () => {
      if (!isAuthenticated || !accessToken) {
        console.error('Authentication required or access token missing.');
        return;
      }

      try {
        const response = await instance.get(
          '/api/knowledgeBase/user-knowledge',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log('Fetched knowledge base entries:', response.data.entries);

        setKnowledgeBase(response.data.entries);

        const uniqueCompanyNames = [
          ...new Set(response.data.entries.map((item) => item.companyName)),
        ];
        setCompanyNames(uniqueCompanyNames);
      } catch (error) {
        console.error('Error fetching user knowledge:', error);
      }
    };

    fetchUserKnowledge();
  }, [isAuthenticated, accessToken]);

  const handleViewFullContent = (knowledgeBaseItemId) => {
    console.log('Attempting to view full content for ID:', knowledgeBaseItemId);
    if (knowledgeBaseItemId) {
      console.log('Opening URL:', `/knowledgeBase/${knowledgeBaseItemId}`);
      window.open(`/knowledgeBase/${knowledgeBaseItemId}`, '_blank');
    } else {
      console.error('Invalid knowledge base item ID');
    }
  };

  const renderedKnowledge = filterAndRenderKnowledge(
    knowledgeBase,
    search,
    companyNameFilter,
    itemNameFilter,
    handleViewFullContent,
    handleItemSelect,
    selectedItems
  );

  return (
    <Container sx={{ padding: 0 }}>
      <Box mt={4} mb={4} sx={{ margin: '2rem 2rem' }}>
        <Typography
          variant="h5"
          component="h3"
          textAlign="center"
          gutterBottom
          mb={4}
        >
          Your Knowledge Base
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="company-name-label">Company Name</InputLabel>
              <Select
                labelId="company-name-label"
                id="company-name-select"
                value={companyNameFilter}
                onChange={handleCompanyNameFilterChange}
                label="Company Name"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {companyNames.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="item-name-label">Item Name</InputLabel>
              <Select
                labelId="item-name-label"
                id="item-name-select"
                value={itemNameFilter}
                onChange={handleItemNameFilterChange}
                label="Item Name"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {itemNameOptions.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" mt={3}>
          <Grid item xs={12}>
            {selectedItems.length > 0 && (
              <Box mb={2}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete Selected ({selectedItems.length})
                </Button>
              </Box>
            )}
            {renderedKnowledge.slice(
              (page - 1) * itemsPerPage,
              page * itemsPerPage
            )}
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil(renderedKnowledge.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                shape="rounded"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirm Bulk Delete'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedItems.length} selected
            items? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleBulkDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserKnowledgeScreen;
