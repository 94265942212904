//client/src/components/screens/Landings/BusinessLanding/BusinessLanding.js

import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import Container from '../../../Container';
import { Hero } from './components';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const BusinessLanding = () => {
  const scrollTo = (id) => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };

  const theme = useTheme();

  const styles = (bgImage) => ({
    position: 'absolute',
    objectFit: 'cover',
    fontFamily: 'object-fit: cover;',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: `url(${bgImage})`,
    filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
  });

  return (
    <HelmetProvider>
      <Box>
        <Helmet>
          <title>AI Tools for Your Business</title>
          <meta
            name="description"
            content="Empower your business with Wisdom Scribe's specialized AI tools. Transform your marketing, reporting, and customer experience with our innovative solutions designed specifically for modern businesses. Increase efficiency and drive growth with our tailored AI technology."
          />
          <meta
            name="keywords"
            content="AI tools for business, business automation, marketing AI, customer experience optimization, business efficiency, Wisdom Scribe"
          />
          <meta property="og:title" content="AI Tools for Business" />
          <meta
            property="og:description"
            content="Transform your business with Wisdom Scribe's specialized AI tools. Optimize marketing, reporting, and customer experience with our innovative business solutions."
          />
          <meta
            property="og:image"
            content="https://wisdomscribe.ai/robot.jpg"
          />

          <meta
            property="og:url"
            content="https://wisdomscribe.ai/landing-business"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Box
          minHeight={'100vh'}
          display={'flex'}
          alignItems={'center'}
          bgcolor={'alternate.main'}
          marginTop={-13}
          paddingTop={13}
        >
          <Container>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Hero />
              <Box marginTop={4}>
                <NoSsr>
                  <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    width={{ xs: 30, sm: 40 }}
                    height={{ xs: 30, sm: 40 }}
                    onClick={() =>
                      scrollTo('agency__portfolio-item--js-scroll')
                    }
                    sx={{ cursor: 'pointer' }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </Box>
                </NoSsr>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          className={'jarallax'}
          data-jarallax
          data-speed="0.2"
          position={'relative'}
          minHeight={'100vh'}
          display={'flex'}
          alignItems={'center'}
          id="agency__portfolio-item--js-scroll"
        >
          <Box
            className={'jarallax-img'}
            sx={styles(
              'https://assets.maccarianagency.com/backgrounds/img48.jpg'
            )}
          />
        </Box>
      </Box>
    </HelmetProvider>
  );
};

export default BusinessLanding;
