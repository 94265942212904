//client/src/components/screens/Account/Profile/Profile.js

import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Page from '../components/Page';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { getAuth } from 'firebase/auth';
import instance from '../../../../helpers/axiosInstance';
import { authState } from '../../../../atoms';

const Profile = () => {
  // Recoil state
  const auth = useRecoilValue(authState);
  const { isAuthenticated } = auth;

  const theme = useTheme();
  const [fullName, setFullName] = React.useState('');
  const [user, setUser] = useState(null);
  const [totalMonthlyCredits, setTotalMonthlyCredits] = useState(0);
  const [creditsUsedForText, setCreditsUsedForText] = useState(0);
  const [creditsUsedForImages, setCreditsUsedForImages] = useState(0);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [usedCreditCount, setUsedCreditCount] = useState(0);
  const [midjourneyImageCreditsUsed, setMidjourneyImageCreditsUsed] =
    useState(0);
  const [stabilityImageCreditsUsed, setStabilityImageCreditsUsed] = useState(0);
  const [audioCreditsUsed, setAudioCreditsUsed] = useState(0);
  const [voiceCreditsUsed, setVoiceCreditsUsed] = useState(0);
  const [chatbotCreditsUsed, setChatbotCreditsUsed] = useState(0);
  const [nextResetCycle, setNextResetCycle] = useState(new Date());

  const calculateNextResetDate = (subscription, subscribedDate) => {
    let date = new Date(subscribedDate);
    switch (subscription) {
      case 'ws-pro-monthly':
      case 'ws-enter-monthly':
      case 'Pro Plan - Monthly':
      case 'Enterprise Plan - Monthly':
        date.setMonth(date.getMonth() + 1);
        return date;
      case 'ws-pro-annual':
      case 'ws-enter-annual':
      case 'Pro Plan - Annual':
      case 'Enterprise Plan - Annual':
        date.setFullYear(date.getFullYear() + 1);
        return date;
      case 'wisdom-personal':
      case 'Personal Plan':
        date.setDate(date.getDate() + 1);
        return date;
      case 'Starter':
        return '-';
      default:
        return date;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!isAuthenticated) {
        console.log('User is not authenticated');
        return;
      }

      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.error('No user available in auth state');
          return;
        }

        const idToken = await user.getIdToken();
        const response = await instance.get('/api/auth/user', {
          headers: { Authorization: `Bearer ${idToken}` },
        });

        console.log('Fetched User Profile:', response.data);
        const userData = response.data;

        setTotalMonthlyCredits(Number(userData.totalMonthlyCreditCount) || 0);
        setCreditsUsedForText(Number(userData.monthlyWordCount) || 0);
        setCreditsUsedForImages(Number(userData.imageCreditsUsed) || 0);
        setMidjourneyImageCreditsUsed(
          Number(userData.midjourneyImageCreditsUsed) || 0
        );
        setStabilityImageCreditsUsed(
          Number(userData.stabilityImageCreditsUsed) || 0
        );
        setAudioCreditsUsed(Number(userData.audioCreditsUsed) || 0);
        setVoiceCreditsUsed(Number(userData.voiceCreditsUsed) || 0);
        setChatbotCreditsUsed(
          Number(userData.totalChatGeneratedWordCount) || 0
        );

        // Calculate total used credits
        const calculatedUsedCredits =
          (Number(userData.monthlyWordCount) || 0) +
          (Number(userData.imageCreditsUsed) || 0) +
          (Number(userData.midjourneyImageCreditsUsed) || 0) +
          (Number(userData.stabilityImageCreditsUsed) || 0) +
          (Number(userData.audioCreditsUsed) || 0) +
          (Number(userData.voiceCreditsUsed) || 0) +
          (Number(userData.totalChatGeneratedWordCount) || 0);

        setUsedCreditCount(calculatedUsedCredits);

        // Calculate remaining credits
        const calculatedRemainingCredits = Math.max(
          (Number(userData.totalMonthlyCreditCount) || 0) -
            calculatedUsedCredits,
          0
        );
        setRemainingCredits(calculatedRemainingCredits);

        setUser(userData);
        setFullName(userData.username);

        const nextResetDate = calculateNextResetDate(
          userData.subscription,
          userData.subscribedDate
        );
        setNextResetCycle(nextResetDate);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        if (error.response && error.response.status === 404) {
          console.error('User data not found');
        } else {
          console.error(
            'Unexpected error:',
            error.message || 'Error fetching user data'
          );
        }
      }
    };

    fetchUserData();
  }, [isAuthenticated]);

  const getSubscriptionDisplayName = (subscription) => {
    if (typeof subscription === 'object' && subscription.planId) {
      switch (subscription.planId) {
        case 'plan_O4k6fe1Pm7MTAp':
        case 'plan_OTjGViF9AoHjfj':
        case 'plan_OTkimcDBwlgDtZ':
          return 'Basic Plan - Monthly';

        case 'plan_O4k8LJAwF5yabp':
        case 'plan_OTjK06XMInoxh5':
        case 'plan_OTkjQf4Eqyg7Nv':
          return 'Basic Plan - Annual';

        case 'plan_O4kH8ErHoetHKu':
        case 'plan_OTkIKT6Rh3IpL5':
        case 'plan_OTkl68jzIQbHSL':
          return 'Pro Plan - Monthly';

        case 'plan_O4kN7DdDaa5Xct':
        case 'plan_OTkIzLSUzATRc5':
        case 'plan_OTklUjpMwpiKoD':
          return 'Pro Plan - Annual';

        case 'plan_OqD5f325DqGdJt':
        case 'plan_OqDOWusfLqjeDk':
        case 'plan_OqDPRI9nvNkPE2':
          return 'Pro Plan - Monthly (with API)';

        case 'plan_OqDUbOBIPmcNkF':
        case 'plan_OqDWNrldZjYL6w':
        case 'plan_OqDYcWsXZOkUNI':
          return 'Pro Plan - Annual (with API)';

        default:
          return subscription.planId || 'No subscription';
      }
    }
    return 'No subscription';
  };

  return (
    <Page>
      <Stack alignItems={'center'} spacing={2}>
        <Avatar
          src={user?.avatar || ''}
          variant={'circular'}
          sx={{
            width: { xs: theme.spacing(16), sm: theme.spacing(20) },
            height: { xs: theme.spacing(16), sm: theme.spacing(20) },
            border: `8px solid ${theme.palette.background.paper}`,
          }}
        />
        <Typography
          color={'text.primary'}
          variant={'h6'}
          align={'center'}
          mb={-5}
        >
          {fullName}
        </Typography>
        <Typography color={'text.secondary'} variant={'body1'} align={'center'}>
          {user?.email}
        </Typography>
        <Typography color={'text.secondary'} variant={'body1'} align={'center'}>
          {user?.role}
        </Typography>

        {user && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{
                    '&:nth-of-type(odd)': {
                      bgcolor: theme.palette.action.hover,
                    },
                  }}
                >
                  <TableCell>Subscription</TableCell>
                  <TableCell>
                    {getSubscriptionDisplayName(user.subscription)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Total Monthly Credits</TableCell>
                  <TableCell>{totalMonthlyCredits}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Credits Used this month</TableCell>
                  <TableCell>{usedCreditCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Credits Used for Text Generation</TableCell>
                  <TableCell>{creditsUsedForText}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Credits Used for Image Generation (Stable Diffusion)
                  </TableCell>
                  <TableCell>{stabilityImageCreditsUsed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Credits Used for Image Generation (MidJourney)
                  </TableCell>
                  <TableCell>{midjourneyImageCreditsUsed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Credits Used for Image Generation (Dall-e)
                  </TableCell>
                  <TableCell>{creditsUsedForImages}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Credits Used for Speech to Text</TableCell>
                  <TableCell>{audioCreditsUsed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Credits Used for Text to Speech</TableCell>
                  <TableCell>{voiceCreditsUsed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Credits Used on Chatbot</TableCell>
                  <TableCell>{chatbotCreditsUsed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Remaining Credits</TableCell>
                  <TableCell>{remainingCredits}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Typography color={'text.secondary'} variant={'h6'} align={'center'}>
          {user?.title || ''}
        </Typography>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 2 }}
          width={1}
          alignItems={'center'}
          mt={2}
        >
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box
              component={'svg'}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width={18}
              height={18}
              color={'text.secondary'}
              marginRight={1}
            ></Box>
          </Box>
        </Stack>
      </Stack>
    </Page>
  );
};

export default Profile;
