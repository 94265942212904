import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../../atoms';
import axiosInstance from '../../helpers/axiosInstance';
import { getAuth } from 'firebase/auth';
import emailVerificationImage from '../../assets/images/emailVerification.svg';

const EmailVerification = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const auth = useRecoilValue(authState);
  const setAuthState = useSetRecoilState(authState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.user) {
      navigate('/login', { replace: true });
    } else if (auth.user.emailVerified) {
      navigate('/dashboard', { replace: true });
    }
  }, [auth, navigate]);

  const handleVerify = async () => {
    try {
      const response = await axiosInstance.post('/api/auth/verify-email', {
        verificationCode,
      });
      setMessage(response.data.message);
      setIsError(false);

      // Refresh the Firebase user
      const firebaseAuth = getAuth();
      await firebaseAuth.currentUser.reload();

      // Update Recoil state
      setAuthState((prevState) => ({
        ...prevState,
        user: {
          ...prevState.user,
          emailVerified: true,
        },
        isAuthenticated: true,
      }));

      // Navigate after a short delay
      setTimeout(() => navigate('/dashboard', { replace: true }), 2000);
    } catch (error) {
      console.error('Verification error:', error);
      setMessage(error.response?.data?.error || 'Verification failed');
      setIsError(true);
    }
  };

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isMd && (
          <Grid item container justifyContent={'center'} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={'img'}
                src={emailVerificationImage}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                }}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="h4" color="text.primary">
              Email Verification
            </Typography>
            <Typography variant="body1" color="text.secondary">
              <br />
              Welcome to Wisdom Scribe! Before you can continue, please verify
              your email address. We've sent a verification code to your email.
              Enter this code below to complete your sign-up process and grant
              you access to all features.
              <br />
              <br />
              If you do not see the email, be sure to check your
              <span
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                }}
              >
                {' '}
                spam folder{' '}
              </span>
              as well. If you encounter any issues, please contact us by
              clicking the chatbot for assistance. We're excited to have you on
              board!
            </Typography>
            <TextField
              label="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleVerify}>
              Verify
            </Button>
            {message && (
              <Typography variant="body2" color={isError ? 'error' : 'success'}>
                {message}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailVerification;
