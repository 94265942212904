// client/src/layouts/Main Sidebar/components/MySidebar/components/SidebarNav/journalistMock.js
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import HistoryIcon from '@mui/icons-material/History';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CollectionsIcon from '@mui/icons-material/Collections';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import ClassIcon from '@mui/icons-material/Class';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SchoolIcon from '@mui/icons-material/School';

const educatorMock = [
  {
    groupTitle: 'AI Tools for Educators',
    id: 'tools',
    pages: [
      {
        title: 'All Teaching Tools',
        path: '/my-teaching-dashboard',
        icon: <SchoolIcon />,
      },
      {
        title: 'Lesson Planning',
        path: '/educator/lesson-planning-tools',
        icon: <ChromeReaderModeIcon />,
      },
      {
        title: 'Assessment Tools',
        path: '/educator/assessment-tools',
        icon: <EditNoteIcon />,
      },
      {
        title: 'Study Aids',
        path: '/educator/study-aids',
        icon: <LocalLibraryIcon />,
      },
      {
        title: 'Homework and Assignment',
        path: '/educator/homework-and-assignment-tools',
        icon: <ClassIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Documents',
    id: 'documents',
    pages: [
      {
        title: 'History',
        path: '/history',
        icon: <HistoryIcon />,
      },
      {
        title: 'Gallery',
        path: '/gallery',
        icon: <CollectionsIcon />,
      },
      {
        title: 'Playlist',
        path: '/playlist',
        icon: <LibraryMusicIcon />,
      },
      {
        title: 'Knowledge Base',
        path: '/my-knowledge-base',
        icon: <TextSnippetIcon />,
      },
      {
        title: 'Tone of Voice',
        path: '/my-tone-of-voice',
        icon: <GraphicEqIcon />,
      },
    ],
  },
  {
    groupTitle: 'Your Account',
    id: 'account',
    pages: [
      {
        title: 'General Settings',
        path: '/user-profile',
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: 'Change Password',
        path: '/update-password',
        icon: <PasswordOutlinedIcon />,
      },
      {
        title: 'Billing Portal',
        path: '/billing-portal',
        icon: <PaymentOutlinedIcon />,
      },
    ],
  },
  {
    groupTitle: 'Support',
    id: 'support',
    pages: [
      {
        title: 'Support',
        path: '#',
        icon: <EmailOutlinedIcon />,
      },
      {
        title: 'Announcements',
        path: '#',
        icon: <CampaignOutlinedIcon />,
      },
    ],
  },
];

export default educatorMock;
