// //client/src/components/routing/ProtectedRoute.js

// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useRecoilValue } from 'recoil';
// import { authState } from '../../atoms';

// const ProtectedRoute = ({ component: Component }) => {
//   const auth = useRecoilValue(authState);

//   if (auth.isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (!auth.isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }

//   if (auth.user && !auth.user.emailVerified) {
//     return <Navigate to="/email-verification" replace />;
//   }

//   return <Component />;
// };

// export default ProtectedRoute;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';

const ProtectedRoute = ({ component: Component }) => {
  const auth = useRecoilValue(authState);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  // Check if the user is authenticated with Google (has a googleId)
  const isGoogleUser = auth.user && auth.user.googleId;

  // Only redirect to email verification if the user is not a Google user and their email is not verified
  if (auth.user && !auth.user.emailVerified && !isGoogleUser) {
    return <Navigate to="/email-verification" replace />;
  }

  return <Component />;
};

export default ProtectedRoute;
