// import React, { useState, useRef, useEffect } from 'react';
// import { useRecoilValue } from 'recoil';
// import { authState } from '../../atoms';
// import { Button } from '../ui/button';
// import { Input } from '../ui/input';
// import { ScrollArea } from '../ui/scroll-area';
// import {
//   Tooltip,
//   TooltipContent,
//   TooltipProvider,
//   TooltipTrigger,
// } from '../ui/tooltip';

// import {
//   Send,
//   X,
//   Book,
//   Database,
//   RefreshCw,
//   Maximize,
//   Minimize,
//   History,
//   MessageSquare,
// } from 'lucide-react';

// const saveHistoryEndpoint = 'http://localhost:3000/api/chat/save-history';

// const chatConfig = {
//   normal: {
//     endpoint: 'http://localhost:3000/api/chat',
//     emoji: '🧑‍💼',
//     titleText: 'Ask Scribe',
//     placeholder: 'Ask me anything...',
//   },
//   retrieval: {
//     endpoint: 'http://localhost:3000/api/chat/retrieval_agents',
//     emoji: '🤖',
//     titleText: 'Ask Link',
//     placeholder: 'Ask me about your database...',
//   },
// };

// const PulsingLoader = () => (
//   <div className="flex space-x-2 justify-center items-center h-6">
//     <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"></div>
//     <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-150"></div>
//     <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-300"></div>
//   </div>
// );

// const TooltipButton = ({ onClick, title, className, children }) => (
//   <TooltipProvider>
//     <Tooltip>
//       <TooltipTrigger asChild>
//         <Button
//           variant="ghost"
//           size="icon"
//           onClick={onClick}
//           className={`text-white hover:bg-gray-800 transition-colors duration-200 ${className}`}
//         >
//           {children}
//         </Button>
//       </TooltipTrigger>
//       <TooltipContent>
//         <p>{title}</p>
//       </TooltipContent>
//     </Tooltip>
//   </TooltipProvider>
// );

// const ChatMessageBubble = ({ message, isLoading = false }) => {
//   const isUser = message.role === 'user';
//   return (
//     <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
//       {!isUser && (
//         <div className="flex items-end mr-2">
//           <img
//             src="/ws_link_bot.png"
//             alt="Link"
//             width={24}
//             height={24}
//             className="flex-shrink-0"
//           />
//         </div>
//       )}
//       <div
//         className={`max-w-[70%] rounded-lg p-3 ${
//           isUser ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
//         }`}
//       >
//         {isLoading ? (
//           <PulsingLoader />
//         ) : (
//           <p className="text-sm leading-relaxed">{message.content}</p>
//         )}
//       </div>
//     </div>
//   );
// };

// const CollectionToggle = ({
//   collectionOption,
//   setCollectionOption,
//   resetChat,
// }) => {
//   const handleCollectionChange = (newOption) => {
//     if (newOption !== collectionOption) {
//       setCollectionOption(newOption);
//       resetChat();
//     }
//   };

//   return (
//     <div className="flex justify-center p-2 bg-gray-100">
//       <div className="flex bg-white rounded-lg shadow-md p-1">
//         <Button
//           variant="ghost"
//           size="sm"
//           onClick={() => handleCollectionChange('embeddings')}
//           className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-all duration-200 ${
//             collectionOption === 'embeddings'
//               ? 'bg-blue-500 text-white'
//               : 'text-gray-600 hover:bg-gray-100'
//           }`}
//         >
//           <Database size={18} />
//           <span className="font-medium">Knowledge Base</span>
//         </Button>
//         <Button
//           variant="ghost"
//           size="sm"
//           onClick={() => handleCollectionChange('syllabus_embeddings')}
//           className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-all duration-200 ${
//             collectionOption === 'syllabus_embeddings'
//               ? 'bg-blue-500 text-white'
//               : 'text-gray-600 hover:bg-gray-100'
//           }`}
//         >
//           <Book size={18} />
//           <span className="font-medium">Syllabus</span>
//         </Button>
//       </div>
//     </div>
//   );
// };

// const ChatHistory = ({ onSelectChat, onClose }) => {
//   const [chatHistories, setChatHistories] = useState([]);
//   const auth = useRecoilValue(authState);
//   const userId = auth.user?.id || auth.user?._id;

//   useEffect(() => {
//     const fetchChatHistories = async () => {
//       try {
//         const response = await fetch(
//           `http://localhost:3000/api/chat/histories?userId=${userId}`
//         );
//         if (response.ok) {
//           const data = await response.json();
//           setChatHistories(data);
//         } else {
//           console.error('Failed to fetch chat histories');
//         }
//       } catch (error) {
//         console.error('Error fetching chat histories:', error);
//       }
//     };

//     fetchChatHistories();
//   }, [userId]);

//   return (
//     <div className="bg-gray-50 border-r border-gray-200 w-72 flex flex-col h-full">
//       <div className="flex justify-between items-center p-4 bg-white border-b border-gray-200">
//         <h3 className="text-lg font-semibold flex items-center">
//           <MessageSquare className="h-5 w-5 mr-2 text-blue-500" />
//           Chat History
//         </h3>
//         {onClose && (
//           <Button
//             variant="ghost"
//             size="sm"
//             onClick={onClose}
//             className="hover:bg-gray-100"
//           >
//             <X className="h-4 w-4" />
//           </Button>
//         )}
//       </div>
//       <ScrollArea className="flex-1 p-4">
//         {chatHistories.length === 0 ? (
//           <div className="flex flex-col items-center justify-center h-full text-gray-500">
//             <MessageSquare className="h-12 w-12 mb-2 opacity-50" />
//             <p className="text-sm text-center">
//               No chat history yet. Start a conversation!
//             </p>
//           </div>
//         ) : (
//           chatHistories.map((chat) => (
//             <div
//               key={chat._id}
//               className="cursor-pointer hover:bg-white p-3 rounded-lg mb-2 transition-all duration-200 group"
//               onClick={() => onSelectChat(chat._id)}
//             >
//               <p className="text-sm font-medium text-gray-800 group-hover:text-gray-900">
//                 {chat.title}
//               </p>
//               <p className="text-xs text-gray-500">
//                 {new Date(chat.timestamp).toLocaleString()}
//               </p>
//             </div>
//           ))
//         )}
//       </ScrollArea>
//     </div>
//   );
// };

// const Chatbot = () => {
//   const auth = useRecoilValue(authState);
//   const userId = auth.user?.id || auth.user?._id;

//   const [chatMode, setChatMode] = useState('normal');
//   const { endpoint, placeholder } = chatConfig[chatMode];

//   const [messages, setMessages] = useState([]);
//   const [input, setInput] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [isFullFrame, setIsFullFrame] = useState(false);
//   const [collectionOption, setCollectionOption] = useState('embeddings');
//   const [showMobileHistory, setShowMobileHistory] = useState(false);
//   const [currentHistoryId, setCurrentHistoryId] = useState(null);
//   const [chatHistories, setChatHistories] = useState([]);

//   const messagesEndRef = useRef(null);

//   useEffect(() => {
//     const checkMobile = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };
//     checkMobile();
//     window.addEventListener('resize', checkMobile);
//     return () => window.removeEventListener('resize', checkMobile);
//   }, []);

//   useEffect(() => {
//     scrollToBottom();
//   }, [messages]);

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   };

//   const saveChatHistory = async (forceSave = false) => {
//     if (messages.length === 0 && !forceSave) return;

//     const endpoint = currentHistoryId
//       ? `${saveHistoryEndpoint}/${currentHistoryId}`
//       : saveHistoryEndpoint;

//     const method = currentHistoryId ? 'PUT' : 'POST';

//     try {
//       const response = await fetch(endpoint, {
//         method: method,
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           userId,
//           messages,
//           chatMode,
//           collectionOption,
//           title:
//             messages.length > 0
//               ? messages[0].content.substring(0, 50) + '...'
//               : 'New Chat',
//           timestamp: new Date(),
//         }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to save chat history');
//       }

//       const savedHistory = await response.json();

//       if (!currentHistoryId) {
//         setCurrentHistoryId(savedHistory._id);
//         updateChatHistoryList(savedHistory);
//       }

//       console.log('Chat history saved successfully');
//     } catch (error) {
//       console.error('Error saving chat history:', error);
//     }
//   };

//   const updateChatHistoryList = (newHistory) => {
//     setChatHistories((prevHistories) => {
//       const index = prevHistories.findIndex((h) => h._id === newHistory._id);
//       if (index !== -1) {
//         // Update existing history
//         const updatedHistories = [...prevHistories];
//         updatedHistories[index] = newHistory;
//         return updatedHistories;
//       } else {
//         // Add new history
//         return [newHistory, ...prevHistories];
//       }
//     });
//   };

//   const loadChatHistory = async (chatId) => {
//     try {
//       const response = await fetch(
//         `http://localhost:3000/api/chat/history/${chatId}`
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setMessages(data.messages);
//         setChatMode(data.chatMode);
//         setCollectionOption(data.collectionOption);
//         setCurrentHistoryId(chatId);
//       } else {
//         console.error('Failed to load chat history');
//       }
//     } catch (error) {
//       console.error('Error loading chat history:', error);
//     }
//   };

//   const sendMessage = async (e) => {
//     e.preventDefault();
//     if (!input.trim() || isLoading || !userId) return;

//     setIsLoading(true);
//     const userMessage = {
//       role: 'user',
//       content: input,
//       id: String(Date.now()),
//     };
//     setMessages((prevMessages) => [...prevMessages, userMessage]);
//     setInput('');

//     const loadingMessage = {
//       role: 'assistant',
//       content: '',
//       id: String(Date.now()),
//       isLoading: true,
//     };
//     setMessages((prevMessages) => [...prevMessages, loadingMessage]);

//     try {
//       const response = await fetch(endpoint, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           messages: [...messages, userMessage],
//           userId: userId,
//           chatMode: chatMode,
//           collectionOption: collectionOption,
//         }),
//       });

//       if (!response.ok) throw new Error(response.statusText);

//       const reader = response.body.getReader();
//       const decoder = new TextDecoder();

//       let assistantMessage = {
//         role: 'assistant',
//         content: '',
//         id: loadingMessage.id,
//         isLoading: false,
//       };

//       while (true) {
//         const { done, value } = await reader.read();
//         if (done) break;

//         const chunk = decoder.decode(value);
//         assistantMessage.content += chunk;

//         setMessages((prevMessages) => {
//           const newMessages = [...prevMessages];
//           newMessages[newMessages.length - 1] = { ...assistantMessage };
//           return newMessages;
//         });

//         await new Promise((resolve) => setTimeout(resolve, 10));
//       }

//       // Save the chat history after the message is complete
//       await saveChatHistory();
//     } catch (error) {
//       console.error('Error sending message:', error);
//       setMessages((prevMessages) => prevMessages.slice(0, -1));
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const toggleChatMode = () => {
//     saveChatHistory(true);
//     setChatMode((prevMode) => (prevMode === 'normal' ? 'retrieval' : 'normal'));
//     resetChat();
//   };

//   const resetChat = () => {
//     saveChatHistory(true);
//     setMessages([]);
//     setInput('');
//     setIsLoading(false);
//     setCurrentHistoryId(null);
//   };

//   const toggleFullFrame = () => {
//     setIsFullFrame(!isFullFrame);
//     if (!isFullFrame) {
//       setIsOpen(true);
//     }
//   };

//   const closeChatbot = () => {
//     saveChatHistory(true);
//     setIsOpen(false);
//     setIsFullFrame(false);
//     setShowMobileHistory(false);
//   };

//   const toggleMobileHistory = () => {
//     setShowMobileHistory(!showMobileHistory);
//   };

//   const handleCollectionChange = (newOption) => {
//     if (newOption !== collectionOption) {
//       saveChatHistory(true);
//       setCollectionOption(newOption);
//       resetChat();
//     }
//   };

//   const chatWindowClasses = isMobile
//     ? 'fixed inset-x-0 top-4 bottom-20 z-[10000] flex flex-col'
//     : isFullFrame
//       ? 'fixed inset-x-0 top-4 bottom-20 z-[10000] flex'
//       : isOpen
//         ? 'fixed bottom-24 right-4 z-[10000] w-[380px] h-[calc(100vh-120px)] flex flex-col'
//         : 'fixed bottom-20 right-4 z-[9999]';

//   const renderChatWindow = () => (
//     <div
//       className={`bg-white flex ${
//         isFullFrame ? 'w-full' : 'flex-col'
//       } h-full border border-gray-200 overflow-hidden rounded-lg shadow-xl`}
//     >
//       {isFullFrame && !isMobile && (
//         <ChatHistory onSelectChat={loadChatHistory} />
//       )}
//       <div className="flex flex-col flex-grow overflow-hidden">
//         <div className="flex-shrink-0">
//           <div className="flex justify-between items-center p-3 bg-gray-900 text-white">
//             <div className="flex items-center space-x-3">
//               <h2 className="text-lg font-semibold whitespace-nowrap">
//                 {chatMode === 'normal' ? 'Ask Scribe' : 'Ask Link'}
//               </h2>
//               <TooltipProvider>
//                 <Tooltip>
//                   <TooltipTrigger asChild>
//                     <Button
//                       variant="outline"
//                       size="sm"
//                       onClick={toggleChatMode}
//                       className="text-white border-white hover:bg-gray-800 transition-colors duration-200"
//                     >
//                       Switch to {chatMode === 'normal' ? 'Link' : 'Scribe'}
//                     </Button>
//                   </TooltipTrigger>
//                   <TooltipContent>
//                     <p>
//                       {chatMode === 'normal'
//                         ? 'Query your own knowledge base and syllabus'
//                         : 'Use a powerful AI chatbot'}
//                     </p>
//                   </TooltipContent>
//                 </Tooltip>
//               </TooltipProvider>
//             </div>
//             <div className="flex items-center space-x-1">
//               {!isMobile && (
//                 <TooltipButton
//                   onClick={toggleFullFrame}
//                   title={
//                     isFullFrame
//                       ? 'Exit full screen mode'
//                       : 'Enter full screen mode'
//                   }
//                 >
//                   {isFullFrame ? (
//                     <Minimize className="h-4 w-4" />
//                   ) : (
//                     <Maximize className="h-4 w-4" />
//                   )}
//                 </TooltipButton>
//               )}
//               {isMobile && (
//                 <TooltipButton
//                   onClick={toggleMobileHistory}
//                   title="View chat history"
//                 >
//                   <History className="h-4 w-4" />
//                 </TooltipButton>
//               )}
//               <TooltipButton
//                 onClick={resetChat}
//                 title="Reset your chat and start anew"
//               >
//                 <RefreshCw className="h-4 w-4" />
//               </TooltipButton>
//               <TooltipButton onClick={closeChatbot} title="Close the chatbot">
//                 <X className="h-4 w-4" />
//               </TooltipButton>
//             </div>
//           </div>
//           {chatMode === 'retrieval' && (
//             <CollectionToggle
//               collectionOption={collectionOption}
//               setCollectionOption={handleCollectionChange}
//               resetChat={resetChat}
//             />
//           )}
//         </div>
//         <ScrollArea className="flex-grow overflow-y-auto bg-white">
//           <div className="space-y-4 p-4">
//             {messages.length === 0 ? (
//               <div className="flex flex-col items-center justify-center h-full text-center text-gray-500 mt-4">
//                 <img
//                   src="/chat-icon.svg"
//                   alt="Start chatting"
//                   className="w-16 h-16 mb-4"
//                 />
//                 <p className="text-lg font-medium">
//                   Start a new conversation or ask a question.
//                 </p>
//                 <p className="text-sm mt-2">I'm here to help!</p>
//               </div>
//             ) : (
//               messages.map((m) => (
//                 <ChatMessageBubble
//                   key={m.id}
//                   message={m}
//                   isLoading={m.isLoading}
//                 />
//               ))
//             )}
//             <div ref={messagesEndRef} />
//           </div>
//         </ScrollArea>
//         <form
//           onSubmit={sendMessage}
//           className="flex-shrink-0 flex items-center p-4 bg-white border-t border-gray-200"
//         >
//           <Input
//             value={input}
//             onChange={(e) => setInput(e.target.value)}
//             placeholder={placeholder}
//             className="flex-1 mr-2 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-full py-2 px-4"
//           />
//           <Button
//             type="submit"
//             size="icon"
//             disabled={isLoading}
//             className="bg-blue-500 hover:bg-blue-600 text-white rounded-full transition-colors duration-200"
//           >
//             <Send className="h-5 w-5" />
//           </Button>
//         </form>
//       </div>
//     </div>
//   );

//   return (
//     <>
//       <div className={chatWindowClasses}>
//         {isOpen || isFullFrame ? (
//           renderChatWindow()
//         ) : (
//           <Button
//             onClick={() => setIsOpen(true)}
//             size="icon"
//             className="rounded-full w-12 h-12 bg-[#1e293b] text-white hover:bg-[#2a3b53] shadow-lg p-0 overflow-hidden"
//           >
//             <img
//               src="/link_bot_white.png"
//               alt="Link Bot"
//               className="w-8 h-8 object-cover transition-colors duration-200"
//             />
//           </Button>
//         )}
//       </div>
//       {isMobile && showMobileHistory && (
//         <div className="fixed inset-0 z-[10001] bg-white">
//           <ChatHistory
//             onSelectChat={(chatId) => {
//               loadChatHistory(chatId);
//               setShowMobileHistory(false);
//             }}
//             onClose={() => setShowMobileHistory(false)}
//           />
//         </div>
//       )}
//     </>
//   );
// };

// export default Chatbot;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { ScrollArea } from '../ui/scroll-area';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

import { Alert, AlertDescription, AlertTitle } from '../ui/alert';

import {
  Send,
  X,
  Book,
  Database,
  RefreshCw,
  Maximize,
  Minimize,
  History,
  MessageSquare,
  Calendar,
  Clock,
  AlertTriangle,
} from 'lucide-react';

const chatConfig = {
  normal: {
    // endpoint: 'http://localhost:3000/api/chat',
    endpoint: 'https://my-chatbot-123565986852.asia-south1.run.app/api/chat',
    emoji: '🧑‍💼',
    titleText: 'Ask Scribe',
    placeholder: 'Ask me anything...',
  },
  retrieval: {
    // endpoint: 'http://localhost:3000/api/chat/retrieval_agents',
    endpoint:
      'https://my-chatbot-123565986852.asia-south1.run.app/api/chat/retrieval_agents',
    emoji: '🤖',
    titleText: 'Ask Link',
    placeholder: 'Ask me about your database...',
  },
};

const PulsingLoader = () => (
  <div className="flex space-x-2 justify-center items-center h-6">
    <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"></div>
    <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-150"></div>
    <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-300"></div>
  </div>
);

const MAX_MESSAGES = 25; // Set your desired maximum number of messages
const WARNING_THRESHOLD = 20; // Set when to start warning the user

const TooltipButton = ({ onClick, title, className, children }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          onClick={onClick}
          className={`text-white hover:bg-gray-800 transition-colors duration-200 ${className}`}
        >
          {children}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>{title}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const formatContent = (content) => {
  // Split content into paragraphs
  const paragraphs = content.split('\n\n');

  return paragraphs.map((paragraph, index) => {
    // Check if the paragraph is a list
    if (paragraph.match(/^\d+\.\s/) || paragraph.match(/^[-*]\s/)) {
      const listItems = paragraph.split('\n');
      return (
        <ul key={index} className="list-disc list-inside mb-4">
          {listItems.map((item, i) => (
            <li key={i}>{item.replace(/^\d+\.\s|^[-*]\s/, '')}</li>
          ))}
        </ul>
      );
    }

    // Check if the paragraph is a code block
    if (paragraph.startsWith('```') && paragraph.endsWith('```')) {
      const code = paragraph.slice(3, -3);
      return (
        <pre
          key={index}
          className="bg-gray-100 p-2 rounded-md mb-4 overflow-x-auto"
        >
          <code>{code}</code>
        </pre>
      );
    }

    // Check for inline code
    const parts = paragraph.split('`');
    if (parts.length > 1) {
      return (
        <p key={index} className="mb-4">
          {parts.map((part, i) =>
            i % 2 === 0 ? (
              part
            ) : (
              <code key={i} className="bg-gray-100 px-1 rounded">
                {part}
              </code>
            )
          )}
        </p>
      );
    }

    // Check for headers
    if (paragraph.startsWith('#')) {
      const level = paragraph.match(/^#+/)[0].length;
      const text = paragraph.replace(/^#+\s/, '');
      const HeaderTag = `h${Math.min(level, 6)}`;
      return (
        <HeaderTag key={index} className="font-bold mb-4">
          {text}
        </HeaderTag>
      );
    }

    // Regular paragraph
    return (
      <p key={index} className="mb-4">
        {paragraph}
      </p>
    );
  });
};

const ChatMessageBubble = ({ message, isLoading = false }) => {
  const isUser = message.role === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      {!isUser && (
        <div className="flex items-end mr-2">
          <img
            src="/ws_link_bot.png"
            alt="Link"
            width={24}
            height={24}
            className="flex-shrink-0"
          />
        </div>
      )}
      <div
        className={`max-w-[70%] rounded-lg p-3 ${
          isUser ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
        }`}
      >
        {isLoading ? (
          <PulsingLoader />
        ) : (
          <div className="text-sm leading-relaxed">
            {formatContent(message.content)}
          </div>
        )}
      </div>
    </div>
  );
};

const CollectionToggle = ({
  collectionOption,
  setCollectionOption,
  resetChat,
}) => {
  const handleCollectionChange = (newOption) => {
    if (newOption !== collectionOption) {
      setCollectionOption(newOption);
      resetChat();
    }
  };

  return (
    <div className="flex justify-center p-2 bg-gray-100">
      <div className="flex bg-white rounded-lg shadow-md p-1">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => handleCollectionChange('embeddings')}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-all duration-200 ${
            collectionOption === 'embeddings'
              ? 'bg-blue-500 text-white'
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          <Database size={18} />
          <span className="font-medium">Knowledge Base</span>
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => handleCollectionChange('syllabus_embeddings')}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-all duration-200 ${
            collectionOption === 'syllabus_embeddings'
              ? 'bg-blue-500 text-white'
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          <Book size={18} />
          <span className="font-medium">Syllabus</span>
        </Button>
      </div>
    </div>
  );
};

const ChatHistory = ({ onSelectChat, onClose, isMobileView }) => {
  const [chatHistories, setChatHistories] = useState([]);
  const auth = useRecoilValue(authState);
  const userId = auth.user?.id || auth.user?._id;

  useEffect(() => {
    const fetchChatHistories = async () => {
      try {
        const response = await fetch(
          `https://my-chatbot-123565986852.asia-south1.run.app/api/chat/histories?userId=${userId}`
          // `http://localhost:3000/api/chat/histories?userId=${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          setChatHistories(data);
        } else {
          console.error('Failed to fetch chat histories');
        }
      } catch (error) {
        console.error('Error fetching chat histories:', error);
      }
    };

    fetchChatHistories();
  }, [userId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className="bg-gray-100 w-full h-full flex flex-col">
      {isMobileView && (
        <div className="flex justify-between items-center p-4 bg-gray-200 border-b border-gray-300">
          <h2 className="text-lg font-semibold text-gray-800">Your History</h2>
          <Button
            variant="ghost"
            size="sm"
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 hover:bg-gray-300"
          >
            <X className="h-5 w-5" />
          </Button>
        </div>
      )}
      <ScrollArea className="flex-1 px-3 py-4">
        {chatHistories.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <MessageSquare className="h-16 w-16 mb-4 opacity-50" />
            <p className="text-lg text-center font-medium">
              No conversations yet
            </p>
            <p className="text-sm text-center mt-2">
              Your chat history will appear here
            </p>
          </div>
        ) : (
          chatHistories.map((chat) => (
            <div
              key={chat._id}
              className="cursor-pointer bg-white hover:bg-blue-50 p-4 rounded-lg mb-3 transition-all duration-200 border border-gray-200 shadow-sm hover:shadow-md"
              onClick={() => onSelectChat(chat._id)}
            >
              <div className="mb-2 line-clamp-2">
                <p className="text-sm font-medium text-gray-800 break-words">
                  {chat.title}
                </p>
              </div>
              <div className="flex items-center justify-between text-xs text-gray-500">
                <div className="flex items-center">
                  <Calendar className="h-3 w-3 mr-1" />
                  <span>{formatDate(chat.updatedAt)}</span>
                </div>
                <div className="flex items-center">
                  <Clock className="h-3 w-3 mr-1" />
                  <span>{formatTime(chat.updatedAt)}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </ScrollArea>
    </div>
  );
};

const Chatbot = () => {
  const auth = useRecoilValue(authState);
  const userId = auth.user?.id || auth.user?._id;

  const [chatMode, setChatMode] = useState('normal');
  const { endpoint, placeholder } = chatConfig[chatMode];

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isFullFrame, setIsFullFrame] = useState(false);
  const [collectionOption, setCollectionOption] = useState('embeddings');
  const [showMobileHistory, setShowMobileHistory] = useState(false);
  const [currentHistoryId, setCurrentHistoryId] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [isNearLimit, setIsNearLimit] = useState(false);
  const [isAtLimit, setIsAtLimit] = useState(false);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const saveChatHistory = useCallback(
    async (forceNewConversation = false) => {
      if (messages.length === 0 && !forceNewConversation) return;

      try {
        const response = await fetch('/api/chat/save-history', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId,
            conversationId: forceNewConversation ? null : conversationId,
            messages,
            chatMode,
            collectionOption,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (
            data.conversationId &&
            (!conversationId || forceNewConversation)
          ) {
            setConversationId(data.conversationId);
          }
        } else {
          console.error('Failed to save chat history');
        }
      } catch (error) {
        console.error('Error saving chat history:', error);
      }
    },
    [userId, conversationId, messages, chatMode, collectionOption]
  );

  const debouncedSaveChatHistory = useCallback(
    debounce(saveChatHistory, 2000),
    [saveChatHistory]
  );

  useEffect(() => {
    if (messages.length > 0) {
      debouncedSaveChatHistory();
    }
  }, [messages, debouncedSaveChatHistory]);

  const resetChat = () => {
    saveChatHistory(true);
    setMessages([]);
    setInput('');
    setIsLoading(false);
    setCurrentHistoryId(null);
    setConversationId(null);
  };

  const loadChatHistory = async (chatId) => {
    try {
      const response = await fetch(
        `https://my-chatbot-123565986852.asia-south1.run.app/api/chat/history/${chatId}`

        // `http://localhost:3000/api/chat/history/${chatId}`
      );
      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages);
        setChatMode(data.chatMode);
        setCollectionOption(data.collectionOption);
        setCurrentHistoryId(chatId);
        setConversationId(chatId);
      } else {
        console.error('Failed to load chat history');
      }
    } catch (error) {
      console.error('Error loading chat history:', error);
    }
  };

  useEffect(() => {
    if (
      messages.length >= WARNING_THRESHOLD &&
      messages.length < MAX_MESSAGES
    ) {
      setIsNearLimit(true);
    } else if (messages.length >= MAX_MESSAGES) {
      setIsAtLimit(true);
    } else {
      setIsNearLimit(false);
      setIsAtLimit(false);
    }
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLoading || !userId || isAtLimit) return;

    if (isAtLimit) {
      alert(
        "You've reached the maximum conversation length. Please start a new chat."
      );
      return;
    }

    setIsLoading(true);
    const userMessage = {
      role: 'user',
      content: input,
      id: String(Date.now()),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');

    const loadingMessage = {
      role: 'assistant',
      content: '',
      id: String(Date.now()),
      isLoading: true,
    };
    setMessages((prevMessages) => [...prevMessages, loadingMessage]);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          messages: [...messages, userMessage],
          userId: userId,
          chatMode: chatMode,
          collectionOption: collectionOption,
          conversationId: conversationId,
        }),
      });

      if (!response.ok) throw new Error(response.statusText);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let assistantMessage = {
        role: 'assistant',
        content: '',
        id: loadingMessage.id,
        isLoading: false,
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);

        // Check if the chunk contains the conversation ID
        if (chunk.includes('__CONVERSATION_ID__')) {
          const [content, conversationIdPart] = chunk.split(
            '__CONVERSATION_ID__'
          );
          assistantMessage.content += content;
          setConversationId(conversationIdPart.trim());
        } else {
          assistantMessage.content += chunk;
        }

        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1] = { ...assistantMessage };
          return newMessages;
        });

        await new Promise((resolve) => setTimeout(resolve, 10));
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => prevMessages.slice(0, -1));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFullFrame = () => {
    setIsFullFrame(!isFullFrame);
    if (!isFullFrame) {
      setIsOpen(true);
    }
  };

  const toggleMobileHistory = () => {
    setShowMobileHistory(!showMobileHistory);
  };

  const toggleChatMode = () => {
    saveChatHistory(true);
    setChatMode((prevMode) => (prevMode === 'normal' ? 'retrieval' : 'normal'));
    resetChat();
  };

  const handleCollectionChange = (newOption) => {
    if (newOption !== collectionOption) {
      saveChatHistory(true);
      setCollectionOption(newOption);
      resetChat();
    }
  };

  const closeChatbot = () => {
    saveChatHistory(true);
    setIsOpen(false);
    setIsFullFrame(false);
    setShowMobileHistory(false);
  };

  const chatWindowClasses = isMobile
    ? isOpen
      ? 'fixed inset-x-0 top-4 bottom-20 z-[10000] flex flex-col'
      : 'fixed bottom-20 right-4 z-[9999]'
    : isFullFrame
      ? 'fixed inset-x-0 top-4 bottom-20 z-[10000] flex'
      : isOpen
        ? 'fixed bottom-24 right-4 z-[10000] w-[380px] h-[calc(100vh-120px)] flex flex-col'
        : 'fixed bottom-20 right-4 z-[9999]';

  const startNewChat = () => {
    resetChat();
    setIsNearLimit(false);
    setIsAtLimit(false);
  };

  const renderChatWindow = () => (
    <div
      className={`bg-white flex ${
        isFullFrame ? 'w-full' : 'flex-col'
      } h-full border border-gray-200 overflow-hidden rounded-lg shadow-xl`}
    >
      {isFullFrame && !isMobile && (
        <div className="w-80 flex-shrink-0 border-r border-gray-200">
          <ChatHistory onSelectChat={loadChatHistory} />
        </div>
      )}
      <div className="flex flex-col flex-grow overflow-hidden">
        {/* Chat header */}
        <div className="flex-shrink-0">
          <div className="flex justify-between items-center p-4 bg-gray-900 text-white">
            <div className="flex items-center space-x-3">
              <h2 className="text-lg font-semibold whitespace-nowrap">
                {chatMode === 'normal' ? 'Ask Scribe' : 'Ask Link'}
              </h2>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={toggleChatMode}
                      className="text-white border-white hover:bg-gray-800 transition-colors duration-200"
                    >
                      Switch to {chatMode === 'normal' ? 'Link' : 'Scribe'}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {chatMode === 'normal'
                        ? 'Query your own knowledge base and syllabus'
                        : 'Use a powerful AI chatbot'}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex items-center space-x-1">
              {!isMobile && (
                <TooltipButton
                  onClick={toggleFullFrame}
                  title={
                    isFullFrame
                      ? 'Exit full screen mode'
                      : 'Enter full screen mode'
                  }
                >
                  {isFullFrame ? (
                    <Minimize className="h-4 w-4" />
                  ) : (
                    <Maximize className="h-4 w-4" />
                  )}
                </TooltipButton>
              )}
              {isMobile && (
                <TooltipButton
                  onClick={toggleMobileHistory}
                  title="View chat history"
                >
                  <History className="h-4 w-4" />
                </TooltipButton>
              )}
              <TooltipButton
                onClick={resetChat}
                title="Reset your chat and start anew"
              >
                <RefreshCw className="h-4 w-4" />
              </TooltipButton>
              <TooltipButton onClick={closeChatbot} title="Close the chatbot">
                <X className="h-4 w-4" />
              </TooltipButton>
            </div>
          </div>
          {chatMode === 'retrieval' && (
            <CollectionToggle
              collectionOption={collectionOption}
              setCollectionOption={handleCollectionChange}
              resetChat={resetChat}
            />
          )}
        </div>

        {/* Warning Alert */}
        {isNearLimit && !isAtLimit && (
          <Alert variant="warning" className="m-2">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Approaching Conversation Limit</AlertTitle>
            <AlertDescription>
              You're nearing the maximum conversation length. Consider starting
              a new chat soon.
            </AlertDescription>
          </Alert>
        )}

        {/* Limit Reached Alert */}
        {isAtLimit && (
          <Alert variant="destructive" className="m-2">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Conversation Limit Reached</AlertTitle>
            <AlertDescription>
              You've reached the maximum conversation length. Please start a new
              chat.
              <Button onClick={startNewChat} className="mt-2" variant="outline">
                Start New Chat
              </Button>
            </AlertDescription>
          </Alert>
        )}

        {/* Chat messages */}
        <ScrollArea className="flex-grow overflow-y-auto bg-white">
          <div className="space-y-4 p-4">
            {messages.map((m) => (
              <ChatMessageBubble
                key={m.id}
                message={m}
                isLoading={m.isLoading}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>

        {/* Chat input */}
        <form
          onSubmit={sendMessage}
          className="flex-shrink-0 flex items-center p-4 bg-white border-t border-gray-200"
        >
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={
              isAtLimit ? 'Max length reached. Start a new chat.' : placeholder
            }
            disabled={isAtLimit}
            className="flex-1 mr-2 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-full py-2 px-4"
          />
          <Button
            type="submit"
            size="icon"
            disabled={isLoading || isAtLimit}
            className="bg-blue-500 hover:bg-blue-600 text-white rounded-full transition-colors duration-200"
          >
            <Send className="h-5 w-5" />
          </Button>
        </form>
      </div>
    </div>
  );

  return (
    <>
      <div className={chatWindowClasses}>
        {isOpen || isFullFrame ? (
          renderChatWindow()
        ) : (
          <Button
            onClick={() => setIsOpen(true)}
            size="icon"
            className="rounded-full w-12 h-12 bg-[#1e293b] text-white hover:bg-[#2a3b53] shadow-lg p-0 overflow-hidden"
          >
            <img
              src="/link_bot_white.png"
              alt="Link Bot"
              className="w-8 h-8 object-cover transition-colors duration-200"
            />
          </Button>
        )}
      </div>
      {isMobile && showMobileHistory && (
        <div className="fixed inset-0 z-[10001] bg-white">
          <ChatHistory
            onSelectChat={(chatId) => {
              loadChatHistory(chatId);
              setShowMobileHistory(false);
            }}
            onClose={() => setShowMobileHistory(false)}
            isMobileView={true}
          />
        </div>
      )}
    </>
  );
};

//   return (
//     <>
//       <div className={chatWindowClasses}>
//         {isOpen || isFullFrame ? (
//           renderChatWindow()
//         ) : (
//           <Button
//             onClick={() => setIsOpen(true)}
//             size="icon"
//             className="rounded-full w-12 h-12 bg-[#1e293b] text-white hover:bg-[#2a3b53] shadow-lg p-0 overflow-hidden"
//           >
//             <img
//               src="/link_bot_white.png"
//               alt="Link Bot"
//               className="w-8 h-8 object-cover transition-colors duration-200"
//             />
//           </Button>
//         )}
//       </div>
//       {isMobile && showMobileHistory && (
//         <div className="fixed inset-0 z-[10001] bg-white">
//           <ChatHistory
//             onSelectChat={(chatId) => {
//               loadChatHistory(chatId);
//               setShowMobileHistory(false);
//             }}
//             onClose={() => setShowMobileHistory(false)}
//           />
//         </div>
//       )}
//     </>
//   );
// };

export default Chatbot;
