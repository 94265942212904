import { Routes, Route as ElementRoute } from 'react-router-dom';
import ReactGA from 'react-ga4';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { RecoilRoot, useRecoilValue } from 'recoil';

import LoadingSpinner from './components/shared-components/LoadingSpinner';

import ScrollToTop from './components/ScrollToTop';
import getTheme from './theme/index';
import Page from './components/Page';
import Main from './layouts/Main/Main';
import AuthManager from './AuthManager';
import ProtectedRoute from './components/routing/ProtectedRoute';

import PaymentVerification from './components/screens/PaymentVerificationScreen';
import SuccessfulVerification from './components/screens/SuccessfulVerification';
import FailedVerification from './components/screens/FailedVerification';
import VerifyEmail from './components/screens/VerifyEmail';
import RegisterScreen from './components/screens/Register/RegisterScreen';
import LoginScreen from './components/screens/Login/LoginScreen';
import EmailVerification from './components/screens/EmailVerificationScreen';
import SelectRole from './components/screens/SelectRole';

import UserHistoryScreen from './components/screens/UserHistoryScreen';
import HistoryDetailScreen from './components/screens/HistoryDetailScreen';
import UserGalleryScreen from './components/screens/UserGalleryScreen';
import UserPlaylistScreen from './components/screens/UserPlaylistScreen';
import ToneOfVoice from './components/screens/ToneOfVoiceUpload';
import KnowledgeBase from './components/screens/KnowledgeBaseUpload';
import SyllabusUpload from './components/screens/SyllabusUpload';
import UserSyllabusScreen from './components/screens/UserSyllabusScreen';
import SyllabusDetailScreen from './components/screens/SyllabusDetailScreen';
import UserKnowledgeScreen from './components/screens/UserKnowledgeBaseScreen';
import KnowledgeDetailScreen from './components/screens/KnowledgeDetailScreen';
import UserToneScreen from './components/screens/UserToneScreen';
import ToneDetailScreen from './components/screens/ToneDetailScreen';
import Home from './components/screens/Home/Home';
import AboutUs from './components/screens/AboutUs/AboutUs';
import ForgotPassword from './components/screens/ForgotPassword/ForgotPassword';
import ResetPassword from './components/screens/PasswordReset/PasswordReset';
import UpdatePassword from './components/screens/Account/Security/Security';
import UserProfile from './components/screens/Account/Profile';
import BillingPortal from './components/screens/Account/Billing';
import ChatWidgetLoader from './components/Chatbot/Chatbot';

//Landing Pages

import BusinessLanding from './components/screens/Landings/BusinessLanding';

import Pricing from './components/screens/Pricing/Pricing';
import SubscriptionConfirmationPage from './components/screens/Pricing/components/Main/SubscriptionConfirmationPage';
import MainSidebar from './layouts/Main Sidebar/Main Sidebar';
import ContactPage from './components/screens/ContactPage/ContactPage';
import SupportPage from './components/screens/SupportPage/SupportPage';
import NewFeature from './components/screens/NewFeature/NewFeature';
import TermsAndPrivacy from './components/screens/CompanyTerms/CompanyTerms';
import Faq from './components/screens/Faq/Faq';
import NotFound from './components/screens/NotFound/NotFound';
import AllTools from './components/screens/Tools/AllTools';
import Dashboard from './components/screens/Tools/Dashboard';
import ContentWriters from './components/screens/Tools/ContentWriters';
import MyContentWriters from './components/screens/Tools/MyContentWriters';
import NewsWriters from './components/screens/Tools/NewsWriters';
import MyNewsWriters from './components/screens/Tools/Journalist/MyNewsWriters';
import MyTeachingTools from './components/screens/Tools/Teaching/MyTeachingTools';
import TeachingTools from './components/screens/Tools/TeachingTools';
import HomeworkAndAssignment from './components/screens/Tools/Teaching/HomeworkAndAssignment';
import StudyAids from './components/screens/Tools/Teaching/StudyAids';
import AssessmentTools from './components/screens/Tools/Teaching/AssessmentTools';
import LessonPlanning from './components/screens/Tools/Teaching/LessonPlanning';
import SpeechToTextTools from './components/screens/Tools/SpeechToTextTools';
import MySpeechToTextTools from './components/screens/Tools/MySpeechToTextTools';
import BusinessTools from './components/screens/Tools/BusinessTools';
import MyBusinessTools from './components/screens/Tools/Business/MyBusinessTools';
import ContentCreation from './components/screens/Tools/Business/ContentCreation';
import CustomerRelations from './components/screens/Tools/Business/CustomerRelations';
import MarketingAndBranding from './components/screens/Tools/Business/MarketingAndBranding';
import BusinessDocumentation from './components/screens/Tools/Business/BusinessDocumentation';
import SeoAndAnalytics from './components/screens/Tools/Business/SeoAndAnalytics';
import SocialMediaToolsBusiness from './components/screens/Tools/Business/SocialMediaTools';
import BroadcastMedia from './components/screens/Tools/Journalist/BroadcastMedia';
import PrintMedia from './components/screens/Tools/Journalist/PrintMedia';
import DigitalMedia from './components/screens/Tools/Journalist/DigitalMedia';
import SocialMedia from './components/screens/Tools/Journalist/SocialMedia';
import SocialMediaTools from './components/screens/Tools/SocialMediaTools';
import MySocialMediaTools from './components/screens/Tools/MySocialMediaTools';
import MyDigitalMarketingTools from './components/screens/Tools/DigitalMarketer/MyDigitalMarketingTools';
import MyWritingTools from './components/screens/Tools/Writer/MyWritingTools';

import MyGeneralTeachingTools from './components/screens/Tools/MyTeachingTools';
import MyGeneralBusinessTools from './components/screens/Tools/MyBusinesTools';
import MyGeneralNewsWriters from './components/screens/Tools/MyNewsWriters';

import BlogWritingTools from './components/screens/Tools/Writer/BlogWritingTools';
import ContentOptimizationTools from './components/screens/Tools/Writer/ContentOptimizationTools';
import SpecializedContentTools from './components/screens/Tools/Writer/SpecializedContent';
import CreativeWritingTools from './components/screens/Tools/Writer/CreativeTools';

import PerformanceAnalysisTools from './components/screens/Tools/DigitalMarketer/PerformanceAnalysis';
import DigitalSeoTools from './components/screens/Tools/DigitalMarketer/SeoTools';
import SocialMediaMarketingTools from './components/screens/Tools/DigitalMarketer/SocialMediaTools';
import DigitalContentGenerationTools from './components/screens/Tools/DigitalMarketer/ContentGenerationTools';
import ContentStrategyAndPlanningTools from './components/screens/Tools/DigitalMarketer/ContentStrategyAndPlanning';

import SeoTools from './components/screens/Tools/SeoTools';
import MySeoTools from './components/screens/Tools/MySeoTools';

import {
  customerEmailResponder,
  performanceReportCreator,
  pitchDeckMaker,
  proposalWriter,
  websiteContentPlanner,
} from './components/screens/Features/AiScreens/BusinessTools/index';

import { createImage } from './components/screens/Features/AiScreens/ImageTools/index';

import {
  analogyMaker,
  blogOutlineBuilder,
  blogWriter,
  blogWriterAida,
  blogWriterBenefits,
  blogWriterHowTo,
  blogWriterPas,
  blogWriterStorytelling,
  faqCreator,
  grammarCorrector,
  headlineIdeasWriter,
  introductionsWriter,
  landingPageHeadlineGenerator,
  narrateToChild,
  outlineBasedBlogwriter,
  productDescriptionGenerator,
  quoteGenerator,
  stepByStepProductGuideWriter,
  textReadabilityScanner,
  translator,
  websitePageContentGenerator,
} from './components/screens/Features/AiScreens/ContentWriters/index';

import {
  breakingNewsAlertGenerator,
  headlineGeneratorBroadcast,
  headlineGeneratorDigital,
  headlineGeneratorPrint,
  interactiveContentGenerator,
  interviewQuestionGenerator,
  newsReportBroadcast,
  newsReportDigital,
  newsReportPrint,
  newsSummaryTool,
  pressReleaseRewriterBroadcast,
  pressReleaseRewriterDigital,
  pressReleaseRewriterPrint,
  socialMediaPostGeneratorNews,
  teaserWriter,
  tickerTextGenerator,
} from './components/screens/Features/AiScreens/NewsWriters/index';
import {
  analogyCreatorTeaching,
  difficultQuestionPaperMaker,
  flashcardMaker,
  homeworkAssigner,
  individualLearningPlanCreator,
  interactiveActivityDesigner,
  lessonPlanner,
  mixedQuestionPaperMaker,
  quizGeneratorTeaching,
  revisionNotes,
  simpleQuestionPaperMaker,
  topicExplainerTeaching,
} from './components/screens/Features/AiScreens/TeachingTools';

import {
  blogContentPlanner,
  blogTopicIdeas,
  keywordResearchTool,
  relatedTopicsResearch,
  simpleKeywordResearchTool,
  ultimateTopicResearcher,
} from './components/screens/Features/AiScreens/SeoTools/index';

import {
  contentIdeasGenerator,
  podcastCreator,
  socialMediaContentGenerator,
  youTubeScriptwriter,
  youTubeTagGenerator,
  youTubeTitleDescriptionKeywordsHashtagsGenerator,
} from './components/screens/Features/AiScreens/SocialMediaTools/index';

import {
  translationFromSpeech,
  transcriptionFromSpeech,
} from './components/screens/Features/AiScreens/SpeechToTextTools/index';

import { textToSpeech } from './components/screens/Features/AiScreens/TextToSpeechTools/index';

import mixpanel from './mixpanelConfig';
import { authState } from './atoms';

// Custom hook for tracking page views
const usePageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track the page view with mixpanel
    mixpanel.track('Page View', {
      page: location.pathname,
    });
  }, [location]);
};

function AppContent() {
  const navigate = useNavigate();

  const [mode, setMode] = useState('light');
  const location = useLocation();
  const authStateValue = useRecoilValue(authState);
  const { isAuthenticated, isLoading, user, userHMAC } = authStateValue;

  useEffect(() => {
    if (!isLoading) {
      const lastRoute = localStorage.getItem('lastRoute');
      const justLoggedIn = localStorage.getItem('justLoggedIn');

      const publicRoutes = [
        '/',
        '/tools',
        '/business-tools',
        '/content-writing-tools',
        '/seo-tools',
        '/social-media-tools',
        '/teaching-tools',
        '/news-writing-tools',
        '/pricing',
        '/about',
        '/contact',
        '/support',
        '/new-feature',
        '/faq',
        '/company-terms',
        '/landing-business',
        '/login',
        '/register',
      ];

      if (isAuthenticated) {
        if (justLoggedIn === 'true') {
          localStorage.removeItem('justLoggedIn');
          navigate('/dashboard', { replace: true });
        } else if (location.pathname === '/login' && lastRoute) {
          navigate(lastRoute, { replace: true });
        } else if (location.pathname !== '/login') {
          localStorage.setItem('lastRoute', location.pathname);
        }
      } else {
        const isPublicRoute = publicRoutes.some((route) =>
          new RegExp(`^${route}/?$`).test(location.pathname)
        );

        if (!isPublicRoute) {
          navigate('/login', { replace: true });
        }
      }
    }
  }, [isAuthenticated, isLoading, location.pathname, navigate]);

  console.log('Current path:', location.pathname);

  usePageViewTracker();

  useEffect(() => {
    ReactGA.initialize('G-FB6N1WG9DY');
    ReactGA.send('pageview', location.pathname);
  }, []);

  useEffect(() => {
    ReactGA.send('pageview', location.pathname);
  }, [location]);

  // Intercom initialization
  useEffect(() => {
    // Initialize Intercom for all users
    window.intercomSettings = {
      app_id: 'ctr4uupb',
    };

    // If user is authenticated, add user details
    if (user && userHMAC) {
      const userId = user.uid || user.id || user._id;
      window.intercomSettings = {
        ...window.intercomSettings,
        name: user.displayName,
        email: user.email,
        user_id: userId,
        user_hash: userHMAC,
      };
    }

    // Load Intercom
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src =
            'https://widget.intercom.io/widget/' + w.intercomSettings.app_id;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }, [user, userHMAC]);

  const themeToggler = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(
    () => createTheme(getTheme(mode, themeToggler)),
    [mode]
  );

  if (authStateValue.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      {isAuthenticated && <ChatWidgetLoader />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Page>
            <Routes>
              <ElementRoute
                exact
                path="/"
                element={
                  <Main>
                    <Home />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/about"
                element={
                  <Main>
                    <AboutUs />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/login"
                element={
                  <Main>
                    <LoginScreen />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/register"
                element={
                  <Main>
                    <RegisterScreen />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/successful-verification"
                element={
                  <Main>
                    <SuccessfulVerification />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/failed-verification"
                element={
                  <Main>
                    <FailedVerification />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/verify-email"
                element={
                  <Main>
                    <VerifyEmail />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/forgot-password"
                element={
                  <Main>
                    <ForgotPassword />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/reset-password/:token"
                element={
                  <Main>
                    <ResetPassword />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/email-verification"
                element={
                  <Main>
                    <EmailVerification />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/select-role"
                element={
                  <Main>
                    <SelectRole />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/success"
                element={
                  <Main>
                    <PaymentVerification />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/tools"
                element={
                  <Main>
                    <AllTools />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/pricing"
                element={
                  <Main>
                    <Pricing />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/landing-business"
                element={
                  <Main>
                    <BusinessLanding />
                  </Main>
                }
              />
              {/* <ElementRoute
                exact
                path="/subscription-confirmation/:planId/:pricingOption/:currency"
                element={
                  <Main>
                    <SubscriptionConfirmationPage />
                  </Main>
                }
              /> */}
              <ElementRoute
                exact
                path="/subscription-confirmation"
                element={
                  <Main>
                    <SubscriptionConfirmationPage />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/company-terms"
                element={
                  <Main>
                    <TermsAndPrivacy />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/faq"
                element={
                  <Main>
                    <Faq />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/contact"
                element={
                  <Main>
                    <ContactPage />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/support"
                element={
                  <Main>
                    <SupportPage />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/new-feature"
                element={
                  <Main>
                    <NewFeature />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/user-profile"
                element={
                  <Main>
                    <ProtectedRoute component={UserProfile} />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/update-password"
                element={
                  <Main>
                    <ProtectedRoute component={UpdatePassword} />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/billing-portal"
                element={
                  <Main>
                    <BillingPortal />
                  </Main>
                }
              />
              {/* <ElementRoute
                exact
                path="/razorpay"
                element={
                  <Main>
                    <Razorpay />
                  </Main>
                }
              /> */}
              <ElementRoute
                exact
                path="/content-writing-tools"
                element={
                  <Main>
                    <ContentWriters />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/news-writing-tools"
                element={
                  <Main>
                    <NewsWriters />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/teaching-tools"
                element={
                  <Main>
                    <TeachingTools />
                  </Main>
                }
              />
              {/* Features screens */}
              <ElementRoute
                exact
                path="/dashboard"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={Dashboard} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-content-writing-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyContentWriters} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-journalist-dashboard"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyNewsWriters} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-teaching-dashboard"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyTeachingTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/educator/lesson-planning-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={LessonPlanning} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/educator/assessment-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={AssessmentTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/educator/study-aids"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={StudyAids} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/educator/homework-and-assignment-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={HomeworkAndAssignment} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/speech-to-text-tools"
                element={
                  <Main>
                    <SpeechToTextTools />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/my-social-media-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MySocialMediaTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-seo-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MySeoTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-teaching-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyGeneralTeachingTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-news-writing-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyGeneralNewsWriters} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-business-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyGeneralBusinessTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/business-tools"
                element={
                  <Main>
                    <BusinessTools />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/seo-tools"
                element={
                  <Main>
                    <SeoTools />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/social-media-tools"
                element={
                  <Main>
                    <SocialMediaTools />
                  </Main>
                }
              />
              <ElementRoute
                exact
                path="/my-speech-to-text-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MySpeechToTextTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-business-dashboard"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyBusinessTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/business/marketing-and-branding"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MarketingAndBranding} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/business/content-creation"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={ContentCreation} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/business/customer-relations"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={CustomerRelations} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/business/business-documentation"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={BusinessDocumentation} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/business/seo-and-analytics"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={SeoAndAnalytics} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/business/social-media-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={SocialMediaToolsBusiness} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/journalist/broadcast-media-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={BroadcastMedia} />
                  </MainSidebar>
                }
              />
              \
              <ElementRoute
                exact
                path="/journalist/broadcast-media-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={BroadcastMedia} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/journalist/digital-media-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={DigitalMedia} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/journalist/print-media-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={PrintMedia} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/journalist/social-media-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={SocialMedia} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-digital-marketing-dashboard"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyDigitalMarketingTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-writing-dashboard"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={MyWritingTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/writer/blog-writing-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={BlogWritingTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/writer/content-optimization-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={ContentOptimizationTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/writer/specialized-content-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={SpecializedContentTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/writer/creative-writing-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={CreativeWritingTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/digital-marketing/seo-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={DigitalSeoTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/digital-marketing/content-strategy-and-planning-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute
                      component={ContentStrategyAndPlanningTools}
                    />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/digital-marketing/content-generation-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={DigitalContentGenerationTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/digital-marketing/social-media-marketing-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={SocialMediaMarketingTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/digital-marketing/performance-analysis-tools"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={PerformanceAnalysisTools} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/customer-email-responder"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={customerEmailResponder} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/performance-report-creator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={performanceReportCreator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/pitch-deck-maker"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={pitchDeckMaker} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/proposal-writer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={proposalWriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/website-content-planner"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={websiteContentPlanner} />
                  </MainSidebar>
                }
              />
              // image generators
              <ElementRoute
                exact
                path="/create-image"
                element={
                  <MainSidebar component={createImage}>
                    <ProtectedRoute component={createImage} />
                  </MainSidebar>
                }
              />
              // content writers
              <ElementRoute
                exact
                path="/analogy-maker"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={analogyMaker} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-outline-builder"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogOutlineBuilder} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-writer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogWriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-writer-aida"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogWriterAida} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-writer-benefits"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogWriterBenefits} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-writer-how-to"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogWriterHowTo} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-writer-pas"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogWriterPas} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-writer-storytelling"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogWriterStorytelling} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/faq-creator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={faqCreator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/grammar-corrector"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={grammarCorrector} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/headline-ideas-writer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={headlineIdeasWriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/introductions-writer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={introductionsWriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/landing-page-headline-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={landingPageHeadlineGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/narrate-to-child"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={narrateToChild} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/outline-based-blog-writer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={outlineBasedBlogwriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/product-description-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={productDescriptionGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/quote-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={quoteGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/step-by-step-product-guide-writer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={stepByStepProductGuideWriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/text-readability-scanner"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={textReadabilityScanner} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/translator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={translator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/website-page-content-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={websitePageContentGenerator} />
                  </MainSidebar>
                }
              />
              //news writers
              <ElementRoute
                exact
                path="/breaking-news-alert-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={breakingNewsAlertGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/headline-generator-broadcast"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={headlineGeneratorBroadcast} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/headline-generator-digital"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={headlineGeneratorDigital} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/headline-generator-print"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={headlineGeneratorPrint} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/headline-generator-print"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={headlineGeneratorPrint} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/interactive-content-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={interactiveContentGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/interview-question-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={interviewQuestionGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/news-report-broadcast"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={newsReportBroadcast} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/news-report-digital"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={newsReportDigital} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/news-report-print"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={newsReportPrint} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/news-summary-tool"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={newsSummaryTool} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/press-release-rewriter-broadcast"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={pressReleaseRewriterBroadcast} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/press-release-rewriter-digital"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={pressReleaseRewriterDigital} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/press-release-rewriter-print"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={pressReleaseRewriterPrint} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/social-media-post-generator-news"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={socialMediaPostGeneratorNews} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/teaser-writer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={teaserWriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/ticker-text-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={tickerTextGenerator} />
                  </MainSidebar>
                }
              />
              //seo tools
              <ElementRoute
                exact
                path="/blog-content-planner"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogContentPlanner} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/blog-topic-ideas"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={blogTopicIdeas} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/keyword-research-tool"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={keywordResearchTool} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/related-topics-research"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={relatedTopicsResearch} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/simple-keyword-research-tool"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={simpleKeywordResearchTool} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/ultimate-topic-researcher"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={ultimateTopicResearcher} />
                  </MainSidebar>
                }
              />
              //social media tools
              <ElementRoute
                exact
                path="/content-ideas-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={contentIdeasGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/podcast-creator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={podcastCreator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/social-media-content-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={socialMediaContentGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/youtube-scriptwriter"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={youTubeScriptwriter} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/youtube-tag-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={youTubeTagGenerator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/youtube-title-description-keywords-hashtags-generator"
                element={
                  <MainSidebar>
                    <ProtectedRoute
                      component={
                        youTubeTitleDescriptionKeywordsHashtagsGenerator
                      }
                    />
                  </MainSidebar>
                }
              />
              // speech to text tools
              <ElementRoute
                exact
                path="/translation-from-speech"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={translationFromSpeech} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/transcription-from-speech"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={transcriptionFromSpeech} />
                  </MainSidebar>
                }
              />
              // teaching tools
              <ElementRoute
                exact
                path="/analogy-creator-teaching"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={analogyCreatorTeaching} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/difficult-question-paper-maker"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={difficultQuestionPaperMaker} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/flashcard-maker"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={flashcardMaker} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/homework-assigner"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={homeworkAssigner} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/individual-learning-plan-creator"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={individualLearningPlanCreator} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/interactive-activity-designer"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={interactiveActivityDesigner} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/lesson-planner"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={lessonPlanner} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/mixed-question-paper-maker"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={mixedQuestionPaperMaker} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/quiz-generator-teaching"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={quizGeneratorTeaching} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/revision-notes"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={revisionNotes} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/simple-question-paper-maker"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={simpleQuestionPaperMaker} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/topic-explainer-teaching"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={topicExplainerTeaching} />
                  </MainSidebar>
                }
              />
              // text to speech tools
              <ElementRoute
                exact
                path="/voice-lab"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={textToSpeech} />
                  </MainSidebar>
                }
              />
              // user related
              <ElementRoute
                exact
                path="/history"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={UserHistoryScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/history/:id"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={HistoryDetailScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/gallery"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={UserGalleryScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/playlist"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={UserPlaylistScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/knowledge-base"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={KnowledgeBase} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/upload-tone"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={ToneOfVoice} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/upload-syllabus"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={SyllabusUpload} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/syllabus"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={UserSyllabusScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/syllabus/:id"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={SyllabusDetailScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-knowledge-base"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={UserKnowledgeScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/knowledgeBase/:id"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={KnowledgeDetailScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-tone-of-voice"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={UserToneScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                exact
                path="/my-tone-of-voice/:id"
                element={
                  <MainSidebar>
                    <ProtectedRoute component={ToneDetailScreen} />
                  </MainSidebar>
                }
              />
              <ElementRoute
                path="*"
                element={
                  <Main>
                    <NotFound />
                  </Main>
                }
              />
            </Routes>
          </Page>
        </div>
      </div>
    </ThemeProvider>
  );
}

function App() {
  return (
    <RecoilRoot>
      <AuthManager>
        <AppContent />
      </AuthManager>
    </RecoilRoot>
  );
}

export default App;
