// //client/src/AuthManager.js

// import { useEffect, useState, useCallback } from 'react';
// import { useSetRecoilState, useRecoilValue } from 'recoil';
// import { authState, currentDashboardState } from './atoms';
// import { auth } from './firebaseConfig';
// import { onAuthStateChanged } from 'firebase/auth';
// import { getUserHMAC } from './api/intercom';
// import axiosInstance from './helpers/axiosInstance';

// const AuthManager = ({ children }) => {
//   const setAuthStateValue = useSetRecoilState(authState);
//   const setCurrentDashboard = useSetRecoilState(currentDashboardState);
//   const currentAuthState = useRecoilValue(authState);
//   const [isInitialized, setIsInitialized] = useState(false);

//   const fetchUserDetails = useCallback(async (user, token) => {
//     try {
//       const userDetailsResponse = await axiosInstance.get('/api/auth/user', {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       return userDetailsResponse.data;
//     } catch (error) {
//       return null;
//     }
//   }, []);

//   const updateAuthState = useCallback(
//     async (user) => {
//       if (user) {
//         try {
//           const token = await user.getIdToken();
//           const userId = user.uid;
//           const userHMAC = await getUserHMAC(userId);
//           const userDetails = await fetchUserDetails(user, token);

//           setAuthStateValue((prevState) => ({
//             user: {
//               ...prevState.user,
//               uid: userId,
//               email: user.email,
//               displayName: user.displayName,
//               photoURL: user.photoURL,
//               emailVerified:
//                 user.emailVerified ||
//                 user.providerData[0]?.providerId === 'google.com',
//               role: userDetails?.role,
//               id: userDetails?.id || userId,
//               _id: userDetails?.id || userId,
//             },
//             accessToken: token,
//             isAuthenticated: true,
//             isLoading: false,
//             userHMAC,
//           }));

//           // Set initial dashboard based on user's role
//           const initialDashboard = getDashboardFromRole(userDetails?.role);
//           setCurrentDashboard(initialDashboard);

//           // Store initial dashboard in localStorage
//           localStorage.setItem('initialDashboard', initialDashboard);
//         } catch (error) {
//           console.error('Error updating auth state:', error);
//           setAuthStateValue({
//             user: null,
//             accessToken: null,
//             isAuthenticated: false,
//             isLoading: false,
//             userHMAC: null,
//             role: null,
//             id: null,
//             _id: null,
//           });
//         }
//       } else {
//         setAuthStateValue({
//           user: null,
//           accessToken: null,
//           isAuthenticated: false,
//           isLoading: false,
//           userHMAC: null,
//           role: null,
//           id: null,
//           _id: null,
//         });
//       }
//     },
//     [setAuthStateValue, setCurrentDashboard, fetchUserDetails, getUserHMAC]
//   );

//   const getDashboardFromRole = (role) => {
//     switch (role) {
//       case 'journalist':
//         return '/my-journalist-dashboard';
//       case 'educator':
//         return '/my-teaching-dashboard';
//       case 'business':
//         return '/my-business-dashboard';
//       case 'digital-marketer':
//         return '/my-digital-marketing-dashboard';
//       case 'writer':
//         return '/my-writing-dashboard';
//       default:
//         return '/dashboard';
//     }
//   };

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (user) => {
//       await updateAuthState(user);
//       setIsInitialized(true);
//     });

//     return () => unsubscribe();
//   }, [updateAuthState]);

//   useEffect(() => {
//     const storedDashboard = localStorage.getItem('currentDashboard');
//     if (storedDashboard) {
//       setCurrentDashboard(storedDashboard);
//     }
//   }, [setCurrentDashboard]);

//   if (!isInitialized) {
//     return null;
//   }

//   return children;
// };

// export default AuthManager;

//client/src/AuthManager.js
import { useEffect, useState, useCallback } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { authState, currentDashboardState } from './atoms';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getUserHMAC } from './api/intercom';
import axiosInstance from './helpers/axiosInstance';

const TOKEN_EXPIRATION_DAYS = 14;

const AuthManager = ({ children }) => {
  const setAuthStateValue = useSetRecoilState(authState);
  const setCurrentDashboard = useSetRecoilState(currentDashboardState);
  const currentAuthState = useRecoilValue(authState);
  const [isInitialized, setIsInitialized] = useState(false);

  const fetchUserDetails = useCallback(async (user, token) => {
    try {
      const userDetailsResponse = await axiosInstance.get('/api/auth/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return userDetailsResponse.data;
    } catch (error) {
      return null;
    }
  }, []);

  const updateAuthState = useCallback(
    async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          const userId = user.uid;
          const userHMAC = await getUserHMAC(userId);
          const userDetails = await fetchUserDetails(user, token);

          // Check if the token has expired
          const tokenExpirationTime = localStorage.getItem(
            'tokenExpirationTime'
          );
          const currentTime = Date.now();
          if (
            tokenExpirationTime &&
            currentTime > parseInt(tokenExpirationTime)
          ) {
            // Token has expired, sign out the user
            await signOut(auth);
            localStorage.removeItem('tokenExpirationTime');
            setAuthStateValue({
              user: null,
              accessToken: null,
              isAuthenticated: false,
              isLoading: false,
              userHMAC: null,
              role: null,
              id: null,
              _id: null,
            });
            return;
          }

          // Set or update the token expiration time
          const newExpirationTime =
            currentTime + TOKEN_EXPIRATION_DAYS * 24 * 60 * 60 * 1000;
          localStorage.setItem(
            'tokenExpirationTime',
            newExpirationTime.toString()
          );

          setAuthStateValue((prevState) => ({
            user: {
              ...prevState.user,
              uid: userId,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
              emailVerified:
                user.emailVerified ||
                user.providerData[0]?.providerId === 'google.com',
              role: userDetails?.role,
              id: userDetails?.id || userId,
              _id: userDetails?.id || userId,
            },
            accessToken: token,
            isAuthenticated: true,
            isLoading: false,
            userHMAC,
          }));

          // Set initial dashboard based on user's role
          const initialDashboard = getDashboardFromRole(userDetails?.role);
          setCurrentDashboard(initialDashboard);

          // Store initial dashboard in localStorage
          localStorage.setItem('initialDashboard', initialDashboard);
        } catch (error) {
          console.error('Error updating auth state:', error);
          setAuthStateValue({
            user: null,
            accessToken: null,
            isAuthenticated: false,
            isLoading: false,
            userHMAC: null,
            role: null,
            id: null,
            _id: null,
          });
        }
      } else {
        setAuthStateValue({
          user: null,
          accessToken: null,
          isAuthenticated: false,
          isLoading: false,
          userHMAC: null,
          role: null,
          id: null,
          _id: null,
        });
        localStorage.removeItem('tokenExpirationTime');
      }
    },
    [setAuthStateValue, setCurrentDashboard, fetchUserDetails, getUserHMAC]
  );

  useEffect(() => {
    // Set initial loading state
    setAuthStateValue((prevState) => ({ ...prevState, isLoading: true }));

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      await updateAuthState(user);
      setIsInitialized(true);
    });

    return () => unsubscribe();
  }, [updateAuthState, setAuthStateValue]);

  const getDashboardFromRole = (role) => {
    switch (role) {
      case 'journalist':
        return '/my-journalist-dashboard';
      case 'educator':
        return '/my-teaching-dashboard';
      case 'business':
        return '/my-business-dashboard';
      case 'digital-marketer':
        return '/my-digital-marketing-dashboard';
      case 'writer':
        return '/my-writing-dashboard';
      default:
        return '/dashboard';
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      await updateAuthState(user);
      setIsInitialized(true);
    });

    return () => unsubscribe();
  }, [updateAuthState]);

  useEffect(() => {
    const storedDashboard = localStorage.getItem('currentDashboard');
    if (storedDashboard) {
      setCurrentDashboard(storedDashboard);
    }
  }, [setCurrentDashboard]);

  if (!isInitialized) {
    return null; // or return a loading spinner
  }

  return children;
};

export default AuthManager;
