import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authState } from '../../atoms';
import { getAuth } from 'firebase/auth';
import instance from '../../helpers/axiosInstance';
import { Box, Typography, Button, Grid } from '@mui/material';

const SelectRole = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [auth, setAuthState] = useRecoilState(authState);
  const user = location.state?.user;

  const roles = [
    { value: 'writer', label: 'Writer' },
    { value: 'journalist', label: 'Journalist' },
    { value: 'educator', label: 'Educator' },
    { value: 'business', label: 'Business Owner' },
    { value: 'digital-marketer', label: 'Digital Marketer' },
  ];

  const handleRoleSelect = async (role) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const response = await instance.put(
        '/api/user/update-role',
        { role },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data.success) {
        setAuthState((prevState) => ({
          ...prevState,
          user: {
            ...prevState.user,
            role: role,
          },
          isAuthenticated: true,
        }));
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Failed to update role:', error);
    }
  };

  return (
    <Box
      px={1}
      width="100%"
      height="100vh"
      mx="auto"
      position="relative"
      zIndex={2}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Box mt={3} mb={1} textAlign="center">
            <Typography variant="h4" color="text.primary" gutterBottom>
              Welcome to Wisdom Scribe!
            </Typography>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              To personalize your experience, please tell us how you identify
              yourself:
            </Typography>
            <Grid container spacing={2} mt={2}>
              {roles.map((role) => (
                <Grid item xs={12} key={role.value}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => handleRoleSelect(role.value)}
                  >
                    {role.label}
                  </Button>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => handleRoleSelect('user')}
                >
                  I'd prefer not to say
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectRole;
