import React from "react";
import { Select, MenuItem } from "@mui/material";

const LanguageSelect = ({ language, setLanguage }) => {
  return (
    <Select
      displayEmpty
      renderValue={(value) => (value !== "" ? value : "Select output language")}
      fullWidth
      value={language}
      onChange={(e) => setLanguage(e.target.value)}
      sx={{ mb: 1.5 }}
    >
      <MenuItem value="English (American)">English (American)</MenuItem>
      <MenuItem value="English (British)">English (British)</MenuItem>
      <MenuItem value="Arabic">Arabic</MenuItem>
      <MenuItem value="Chinese">Chinese</MenuItem>
      <MenuItem value="Danish">Danish</MenuItem>
      <MenuItem value="Dutch">Dutch</MenuItem>
      <MenuItem value="French">French</MenuItem>
      <MenuItem value="German">German</MenuItem>
      <MenuItem value="Greek">Greek</MenuItem>
      <MenuItem value="Hindi">Hindi</MenuItem>
      <MenuItem value="Italian">Italian</MenuItem>
      <MenuItem value="Japanese">Japanese</MenuItem>
      <MenuItem value="Korean">Korean</MenuItem>
      <MenuItem value="Persian (Farsi)">Persian (Farsi)</MenuItem>
      <MenuItem value="Polish">Polish</MenuItem>
      <MenuItem value="Portuguese">Portuguese</MenuItem>
      <MenuItem value="Russian">Russian</MenuItem>
      <MenuItem value="Spanish">Spanish</MenuItem>
      <MenuItem value="Swedish">Swedish</MenuItem>
      <MenuItem value="Tamil">Tamil</MenuItem>
      <MenuItem value="Turkish">Turkish</MenuItem>
    </Select>
  );
};

export default LanguageSelect;
